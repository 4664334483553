<template>
  <div>
    <button
      class="btn btn-outline-blue mb-3"
      @click="openAddProviderModal"
      v-if="
        user &&
        (user.isAdmin || user.isManagingAdmin) &&
        $can({ key: 'clients', expectedPermission: 'edit' })
      "
    >
      <icon type="plus" /> Add Providers
    </button>

    <modal
      class="add-provider-modal"
      name="add-provider-modal"
      transition="pop-out"
      :height="600"
      :width="1200"
    >
      <div class="position-relative modal-body">
        <div class="vm--top-right-slot" style="z-index: 1">
          <button class="btn p-2 border-0" @click="closeAddProviderModal">
            <i class="fas fa-times fa-lg"></i>
          </button>
        </div>
        <div class="body-content modal-body-container">
          <ListProviders
            v-bind:hideDetails="true"
            :connect="
              (provider) => connect({ patientId: patient.id, provider })
            "
          ></ListProviders>
        </div>
      </div>
    </modal>
    <alert class="my-4 light-shadow" v-if="providers.isLoading" />
    <alert
      class="my-4 light-shadow"
      :hideLoader="true"
      v-if="!providers.isLoading && providers.data.length == 0"
      >No Providers</alert
    >

    <div
      class="card light-shadow mobile-tablet-no-card table-container border-0"
      v-if="!providers.isLoading && providers.data.length"
    >
      <table
        class="
          table
          overflow-auto
          table-striped
          mb-0
          responsive-mobile-tablet-table
        "
      >
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Email</th>
            <th>Status</th>
            <th>Hired On</th>
            <th v-if="user.isAdmin || user.isManagingAdmin">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="p in providers.data" :key="p.id">
            <td><b class="mobile-tablet-only mr-2">ID:</b>{{ p.user_id }}</td>
            <td>
              <div
                class="d-inline-flex align-items-center justify-content-center"
              >
                <b class="mobile-tablet-only mr-2">Name:</b>
                <img
                  class="user-avatar-img mr-2 mr-lg-3 rounded-circle"
                  :src="p.profile_picture || profileImagePlaceholder"
                />
                <router-link
                  :to="`/providers/${p.id}`"
                  v-if="user && !user.isPatient && !user.isProvider"
                  >{{ p.last }}, {{ p.first }}
                </router-link>
                <span v-else>{{ p.last }}, {{ p.first }}</span>
              </div>
            </td>
            <td>
              <b class="mobile-tablet-only mr-2">Email:</b
              ><a :href="`mailto:${p.email}`">{{ p.email }}</a>
            </td>
            <td>
              <b class="mobile-tablet-only mr-2">Status:</b
              ><span
                :class="`${
                  p.status == 'ACTIVE'
                    ? 'text-success'
                    : p.status == 'PENDING'
                    ? 'text-warning'
                    : 'text-danger'
                }`"
                >{{ p.status }}</span
              >
            </td>
            <td>
              <b class="mobile-tablet-only mr-2">Hired On:</b
              >{{ formattedDate(p.hired_at) }}
            </td>
            <td
              v-if="user.isAdmin || user.isManagingAdmin"
              class="text-center td-actions"
            >
              <span
                class="ml-2 text-danger pointer-cursor"
                v-if="$can({ key: 'providers', expectedPermission: 'edit' })"
                ><b
                  class="mobile-tablet-only mr-2"
                  @click="!p.disconnecting && !disconnectPatient(p)"
                  >Disconnect</b
                >
                <span
                  class="spinner-border spinner-border-sm"
                  v-if="p.disconnecting"
                ></span>
                <i
                  class="fas fa-unlink"
                  @click="disconnectPatient(p)"
                  v-else
                ></i
              ></span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import { default as ListProviders } from "../../../providers/ListProviders.vue";
import * as dayjs from "dayjs";
import { mapState, mapActions } from "vuex";
import Swal from "sweetalert2";

export default {
  name: "patient.providers",
  components: { ListProviders },
  data() {
    return {
      profileImagePlaceholder: require("@/assets/img/avatars/profiles/avatar.jpg"),
    };
  },
  computed: {
    ...mapState({
      patient: (state) => state.patients.patient,
      loading: (state) => state.patients.isLoading,
      providers: (state) => state.patients.providers,
      user: (state) => state.auth.user,
    }),
  },
  mounted() {
    if (!this.loading) {
      this.get(this.patient.id);
    }
  },
  methods: {
    ...mapActions({
      get: "patients/providers/getBasicProviders",
      connect: "patients/providers/connect",
      disconnect: "providers/patients/disconnect",
    }),
    openAddProviderModal() {
      this.$modal.show("add-provider-modal");
    },
    closeAddProviderModal() {
      this.$modal.hide("add-provider-modal");
    },
    formattedDate: function (dateToformat) {
      if (dateToformat && dateToformat != "") {
        return dayjs(dateToformat).format("YYYY-MM-DD");
      } else {
        return "";
      }
    },
    disconnectPatient: function (provider) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-black ml-4",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });
      swalWithBootstrapButtons
        .fire({
          title: "Are you sure?",
          text: "You are going to disconnect this provider",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, disconnect",
          cancelButtonText: "No, cancel!",
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            const data = {
              providerId: provider.id,
              patientId: this.patient.id,
            };
            provider.disconnecting = true;
            this.$forceUpdate();
            this.disconnect(data).then((disconnected) => {
              provider.disconnecting = false;
              this.$forceUpdate();
              if (disconnected) {
                this.get(this.patient.id);
              }
            });
          }
        });
    },
  },
};
</script>
<style scoped>
.user-avatar-img {
  width: 45px;
  min-width: 45px;
  height: 45px;
}
</style>
